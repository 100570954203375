<template>
  <div class="relative min-h-screen">
    <div class="text-right">
      <button
        @click="EditRole()"
        type="button"
        class="text-white bg-green-700  md:text-xs mb-3  mt-3 hover:text-black hover:bg-white  pl-4 pt-2 pb-2 pr-4 mr-2 rounded-full"
      >
        Edit Role
      </button>
      <button
        @click="showDeleteModal = true"
        type="button"
        class="text-white bg-red-700  hover:bg-white  hover:text-black md:text-xs mb-3  mt-3   pl-4 pt-2 pb-2 pr-4  rounded-full"
      >
        Delete Role
      </button>
    </div>
    <h1 class="font-bold text-lg text-center text-darkblue mb-3">
      Single Role
    </h1>
    <div class="ml-auto mr-auto   rounded-lg md:max-w-3/4">
      <h3 class="text-black text-center ">
        <span Edclass="font-bold"> Name: </span>{{ role.name }}
      </h3>
      <div class="text-center mt-1 mb-2">
        <font-awesome-icon
          v-if="loadingRole"
          title="Close"
          :class="'text-dark animate-spin text-base mr-1 '"
          :icon="['fas', 'spinner']"
        />
      </div>
    </div>
    <div>
      <transition name="slide-fade">
        <NotificationModal
          v-if="showDeleteModal"
          :modalData="modalData"
          @modal-success="DeleteRole($event)"
          @modal-close="CloseModal($event)"
        />
      </transition>
    </div>
    <div>
      <div class="mt-6 mb-3 overflow-auto">
        <h5 class="text-center mb-3 text-darkblue">
          Role Permissions
        </h5>
        <t-table
          id="my-table"
          :classes="tableClasses"
          :headers="tableHeader"
          :data="role.permissions"
        >
          <template slot="column" slot-scope="props">
            <td v-if="props.text === 'action'" :class="props.tdClass">
              <button
                @click="DeletePermission(props.rowIndex)"
                class="   focus:bg-gray-100"
              >
                <font-awesome-icon
                  title="Delete"
                  :class="'text-red-600 hover:text-black text-sm'"
                  :icon="['far', 'trash-alt']"
                />
              </button>
            </td>
            <td v-else :class="props.tdClass">
              <span v-if="props.text === true">
                <font-awesome-icon
                  title="True"
                  :class="'text-green-600 hover:text-black text-sm'"
                  :icon="['fas', 'check-circle']"
                />
              </span>
              <span v-else-if="props.text === false">
                <font-awesome-icon
                  title="False"
                  :class="'text-red-600 hover:text-black text-sm'"
                  :icon="['fas', 'times-circle']"
                />
              </span>
              <span v-else>
                {{ props.text }}
              </span>
            </td>
          </template>
        </t-table>
        <p class="text-sm text-center" v-if="role.permissions.length < 1">
          No permissions available for this role
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
  import NotificationModal from "../../components/NotificationModal.vue";
  import gql from "graphql-tag";
  import { mapMutations } from "vuex";

  export default {
    name: "SingleRole",
    props: {},
    components: { FontAwesomeIcon, NotificationModal },
    apollo: {
      role: {
        query: gql`
          query role($id: ID!) {
            role(id: $id) {
              _id
              name
              serialId
              permissions {
                label
                create
                read
                edit
                delete
              }

              createdAt
            }
          }
        `,
        variables() {
          return {
            id: this.$route.params.id,
          };
        },

        error(error) {
          this.$emit("show-alert", {
            type: "error",
            message: error.message,
          });
        },
        watchLoading(isLoading, countModifier) {
          if (isLoading === true && countModifier === 1) {
            this.loadingSpinner = true;
            return;
          }

          this.loadingSpinner = false;
        },
      },
    },
    data() {
      return {
        showDeleteModal: false,
        modalData: {
          title: "Delete Role",
          message:
            "Are you sure you want to delete this role? You can't get it back if you continue.",
          cancelText: "Cancel",
          successText: "Delete",
          loading: false,
        },
        permissionModal: false,
        tableClasses: {
          table:
            "min-w-full text-darkblue divide-y md:text-center divide-gray-100 shadow-sm border-gray-200 border",
          thead: "border-b-2 border-darkblue ",
          theadTr: " md:text-xs ",
          theadTh:
            "px-3 py-2 font-bold md:text-center text-left bg-transparent ",
          tbody: "bg-white divide-y divide-gray-100",
          tr: "rounded-lg  md:text-xs  text-xs ",
          td: "px-3 py-2 whitespace-no-wrap",
          tfoot: "",
          tfootTr: "",
          tfootTd: "",
        },

        tableHeader: [
          {
            id: "itemName",
            value: "label",
            text: "Label",
            className: "",
          },
          {
            value: "create",
            text: "Create",
            className: "fortunate-table",
          },
          {
            value: "read",
            text: "Read",
            className: "fortunate-table",
          },
          {
            value: "edit",
            text: "Edit",
            className: "fortunate-table",
          },
          {
            value: "delete",
            text: "Delete",
            className: "fortunate-table",
          },
        ],
        selectedPermission: {
          label: "",
          create: false,
          read: false,
          edit: false,
          delete: false,
          action: "action",
        },
        permissionOptions: [
          { value: "role", text: "Role" },
          { value: "user", text: "User" },
          { value: "customer", text: "Customer" },
          { value: "stock", text: "Stock" },
          { value: "purchase", text: "Purchase" },
          { value: "expense", text: "Expense" },
          { value: "invoice", text: "Invoice" },
          { value: "expenseItem", text: "ExpenseItem" },
          { value: "partner", text: "Partner" },
          { value: "quote", text: "Quote" },
          { value: "statement", text: "Statement" },
        ],
        loadingRole: false,
        loadingSpinner: false,
        role: {
          name: "",
          permissions: [],
        },
        rich_select_default_component_classes: {
          wrapper: "",
          buttonWrapper: "",
          selectButton:
            "px-3 rounded-lg  md:text-xs  py-2 text-black transition duration-100 ease-in-out bg-white border-darkblue border-2 rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
          selectButtonLabel: "",
          selectButtonPlaceholder: "text-darkblue",
          selectButtonIcon: "text-gray-600",
          selectButtonClearButton:
            "hover:bg-blue-100 text-gray-600 rounded transition duration-100 ease-in-out",
          selectButtonClearIcon: "",
          dropdown:
            "-mt-1 bg-white  md:text-xs  border-b border-gray-300 border-l border-r rounded-b shadow-sm",
          dropdownFeedback: "pb-2  md:text-xs  px-3 text-gray-400 text-sm",
          loadingMoreResults: "pb-2 px-3 text-gray-400 text-sm",
          optionsList: "",
          searchWrapper: "p-2 placeholder-gray-400",
          searchBox:
            "px-3 py-2 bg-gray-50 text-sm  md:text-xs  rounded border focus:outline-none focus:shadow-outline border-gray-300",
          optgroup: "text-gray-400 uppercase text-xs py-1 px-2 font-semibold",
          option: "",
          disabledOption: "",
          highlightedOption: "bg-blue-100",
          selectedOption:
            "font-semibold bg-gray-100 bg-blue-500 font-semibold text-white",
          selectedHighlightedOption:
            "font-semibold bg-gray-100 bg-darkblue font-semibold text-white",
          optionContent: "flex justify-between items-center px-3 py-2",
          optionLabel: "",
          selectedIcon: "",
          enterClass: "",
          enterActiveClass: "opacity-0 transition ease-out duration-100",
          enterToClass: "opacity-100",
          leaveClass: "transition ease-in opacity-100",
          leaveActiveClass: "",
          leaveToClass: "opacity-0 duration-75",
        },
        rich_select_component_classes: {
          wrapper: "relative",
          buttonWrapper: "inline-block relative w-full",
          selectButton: "w-full flex text-left justify-between items-center",
          selectButtonLabel: "block truncate",
          selectButtonPlaceholder: "block truncate",
          selectButtonIcon: "fill-current flex-shrink-0 ml-1 h-4 w-4",
          selectButtonClearButton:
            "flex flex-shrink-0 items-center justify-center absolute right-0 top-0 m-2 h-6 w-6",
          selectButtonClearIcon: "fill-current h-3 w-3",
          dropdown: "absolute w-full z-10",
          dropdownFeedback: "",
          loadingMoreResults: "",
          optionsList: "overflow-auto",
          searchWrapper: "inline-block w-full",
          searchBox: "inline-block w-full",
          optgroup: "",
          option: "cursor-pointer capitalize",
          disabledOption: "opacity-50 cursor-not-allowed",
          highlightedOption: "cursor-pointer",
          selectedOption: "cursor-pointer",
          selectedHighlightedOption: "cursor-pointer",
          optionContent: "",
          optionLabel: "truncate block",
          selectedIcon: "fill-current h-4 w-4",
          enterClass: "",
          enterActiveClass: "",
          enterToClass: "",
          leaveClass: "",
          leaveActiveClass: "",
          leaveToClass: "",
        },
      };
    },
    watch: {
      editPermission: function(newValue) {
        newValue ? (this.selectedPermission.read = true) : "";
      },
      deletePermission: function(newValue) {
        newValue ? (this.selectedPermission.read = true) : "";
      },
    },
    computed: {},
    methods: {
      ...mapMutations(["setBreadcrumbs"]),
      CloseModal() {
        this.showDeleteModal = false;
        this.modalData.loading = false;
      },
      EditRole() {
        this.$router.push({
          name: "manageRole",
          params: { id: this.$route.params.id, mode: "edit" },
        });
      },
      DeletePermission(index) {
        this.$emit("show-alert", {
          type: "notification",
          timeout: 3500,

          message: `${this.role.permissions[index].label} has been deleted from permission list`,
        });
        this.role.permissions.splice(index, 1);
      },
      AddPermission() {
        const duplicatePermissionCheck = this.role.permissions.find(
          (permission) => {
            return this.selectedPermission.label === permission.label;
          }
        );

        if (duplicatePermissionCheck) {
          return this.$emit("show-alert", {
            type: "error",

            message: `${this.selectedPermission.label} can't be added twice to the permission list `,
          });
        }
        const permission = { ...this.selectedPermission };
        this.role.permissions.push(permission);
        this.$emit("show-alert", {
          type: "notification",
          timeout: 3500,

          message: `${this.selectedPermission.label} has been added to permission list`,
        });
        this.selctedPermission = {
          label: "",
          create: false,
          read: false,
          edit: false,
          delete: false,
        };
      },
      ShowPermissionModal() {
        this.permissionModal = true;
      },
      SubmitRole() {
        if (this.role.permissions.length < 1) {
          return this.$emit("show-alert", {
            type: "error",

            message: `Add permissions to this role before you can save it `,
          });
        }
        if (this.$route.params.mode != "edit") {
          this.CreateRole();
          return;
        }
        this.UpdateRole();
      },
      async CreateRole() {
        this.loadingSpinner = true;
        try {
          const roleData = { ...this.role };
          roleData.permissions.forEach((permission) => {
            delete permission.action;
          });
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($role: RoleInput!) {
                createRole(role: $role) {
                  _id
                }
              }
            `,
            // Parameters
            variables: {
              role: roleData,
            },
          });

          this.loadingSpinner = false;
          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          if (!errors && !!data.createRole._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Role created successfully`,
            });

            return this.$router.push("/app/roles");
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating role,check your network or contact your developer`,
          });
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error creating role,check your network or contact your developer`,
          });
        }
      },
      async UpdateRole() {
        this.loadingSpinner = true;
        try {
          const roleData = { ...this.role };
          roleData.permissions.forEach((permission) => {
            delete permission.action;
          });
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!, $role: RoleInput!) {
                updateRole(id: $id, user: $role) {
                  _id
                  serialId
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
              role: roleData,
            },
          });

          this.loadingSpinner = false;
          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          if (!errors && !!data.updateUser._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Role updated successfully`,
            });

            return this.$router.push("/app/roles");
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating role,check your network or contact your developer`,
          });
        } catch (error) {
          this.loadingSpinner = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error updating role,check your network or contact your developer`,
          });
        }
      },
      async DeleteRole() {
        this.modalData.loading = true;

        try {
          const { data, errors } = await this.$apollo.mutate({
            // Query
            mutation: gql`
              mutation($id: ID!) {
                deleteRole(id: $id) {
                  _id
                  serialId
                  isDeleted
                }
              }
            `,
            // Parameters
            variables: {
              id: this.$route.params.id,
            },
          });

          if (!errors && !!data.deleteRole._id) {
            this.$emit("show-alert", {
              type: "success",
              message: `Role deleted successfully`,
            });

            return this.$router.push("/app/roles");
          }
          this.modalData.loading = false;

          if (!!errors && data == null) {
            return this.$emit("show-alert", {
              type: "error",
              message: errors[0].message,
            });
          }

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting role,check your network or contact your developer`,
          });
        } catch (error) {
          this.modalData.loading = false;

          this.$emit("show-alert", {
            type: "error",
            message: `Error deleting role,check your network or contact your developer`,
          });
        }
      },
    },
    mounted() {
      this.setBreadcrumbs([
        { displayName: "Roles", pathName: "roles" },

        {
          displayName: "Single Role",
          pathName: "singleRole",
          params: { id: this.$route.params.id },
        },
      ]);
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
